<template>
    <b-card-code title="Anggota" no-body>

        <b-card-body>
            <b-row>
                <b-col lg="20" md="12" class="mb-50">
                    <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-primary" class="mt-2 mt-md-0"
                        @click="showMore()">
                        <feather-icon icon="ArrowRightIcon" class="mr-26" />
                        <span>Show More</span>
                    </b-button>
                </b-col>
            </b-row>
        </b-card-body>
        <!-- table -->
        <b-table ref="selectableTable" selectable :select-mode="selectMode" :items="items" :fields="fields" responsive
            class="mb-0" @row-selected="onRowSelected">
            <template #cell(document)="data">
                <a :href="data.item.document" v-if="data.item.document" target="_blank">Open</a>
            </template>
            <template #cell(status)="data">
                <b-badge :variant="status[1][data.value]">
                    {{ status[0][data.value] }}
                </b-badge>
            </template>
            <!-- <template #cell(action)="data">
                <b-dropdown variant="link" no-caret toggle-class="p-0" right>
                    <template #button-content>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon">
                            <feather-icon icon="SettingsIcon" />
                        </b-button>
                    </template>

                    <b-dropdown-item @click="showDetailGaleri(data.item)">
                        <feather-icon icon="SettingsIcon" size="16" />
                        <span class="align-middle ml-50">Detail</span>
                    </b-dropdown-item>

                    <b-dropdown-item @click="modalUpdateGaleri(data.item)">
                        <feather-icon icon="CheckSquareIcon" size="16" />
                        <span class="align-middle ml-50">Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="deleteDataGallery(data.item)">
                        <feather-icon icon="XSquareIcon" size="16" />
                        <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item>
                </b-dropdown>
            </template> -->
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <div></div>
            <!--pagination -->
            <div class="demo-inline-spacing pt-0">
                <b-pagination v-model="currentPage" hide-goto-end-buttons :total-rows="rows" :per-page="perPage" />
            </div>
        </b-card-body>
    </b-card-code>
</template>


<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
    BCard, BButton, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BTable, BPagination,
    BButtonToolbar, BButtonGroup, BDropdown, BDropdownItem, BFormInput, BModal, BFormFile
} from 'bootstrap-vue'

//call API
import API from '@/api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'


export default {
    components: {
        BCardCode,
        BTable,
        BRow,
        BButton,
        BCol,
        BCard,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BMedia,
        BAvatar,
        BMediaAside,
        BMediaBody,
        API,
        vSelect,
        Ripple,
        BPagination,
        BButtonToolbar,
        BButtonGroup,
        BDropdown,
        BDropdownItem,
        BFormInput,
        BModal,
        BFormFile
    },
    data() {
        return {
            currentPage: 1,
            perPage: 20,
            rows: 0,

            calon_pengguna: '',
            anggota_idi_jakpus: '',
            anggota_non_idi_jakpus: '',
            studi_kasus: '',

            selected: [],


            fields: [{ key: 'id', label: 'id' }, { key: 'orang_email', label: 'email' }, { key: 'orang_nama_lengkap', label: 'nama' }, { key: 'orang_tanggal_lahir', label: 'tanggal lahir' }, { key: 'orang_tempat_lahir', label: 'tempat lahir' }, { key: 'orang_level', label: 'level anggota' }],
            items: [],

            selectMode: 'multi',
        }
    },
    methods: {

        onRowSelected(items) {
            this.selected = items
        },
        selectAllRows() {
            this.$refs.selectableTable.selectAllRows()
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected()
        },

        refreshPage() {
            //cuurent page dashboard
            if (this.currentPage == 1) {
                this.anggota()
            } else {
                this.currentPage = 1
            }

        },

        showMore() {
            this.$router.push('anggota')
        },

        async anggota() {
            try {
                this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)

                const form = {
                    limit: this.perPage,
                    page: this.currentPage,
                    search: this.search,
                }
                const { data } = await API.dashboard.list(form)
                this.items = data.anggota_baru
                this.handleMsgSuccess('Berhasil tampilkan data calon anggota baru')
            } catch {
                this.handleMsgSuccess(error, 'gagal menampilkan anggota baru')
            } finally {

                this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
            }
        }

    },

    mounted() {
        this.anggota()
    },

    watch: {
        currentPage() {
            this.anggota()
        }
    }
}
</script>
<style lang="scss">
.b-table-selectable {
    .feather {
        font-size: 1.3rem;
    }
}
