<template>
    <b-card no-body class="card-statistics">
        <b-card-header>
            <b-card-title>Analytics</b-card-title>
        </b-card-header>
        <b-card-body class="statistics-body">
            <b-row>
                <b-col :key="UserPlusIcon" md="3" sm="6" class="mb-2 mb-md-0">
                    <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar size="48" variant="light-primary">
                                <feather-icon size="24" icon="UserPlusIcon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                                {{ calon_pengguna }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                                CALON PENGGUNA
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                </b-col>
                <b-col :key="UserIcon" md="3" sm="6" class="mb-2 mb-md-0">
                    <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar size="48" variant="light-info">
                                <feather-icon size="24" icon="UserIcon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                                {{ anggota_idi_jakpus }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                                AGT IDI JAKPUS
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                </b-col>
                <b-col :key="UserXIcon" md="3" sm="6" class="mb-2 mb-md-0">
                    <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar size="48" variant="light-danger">
                                <feather-icon size="24" icon="UserXIcon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                                {{ anggota_non_idi_jakpus }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                                AGT NON IDI JAKPUS
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                </b-col>
                <b-col :key="BookmarkIcon" md="3" sm="6" class="mb-2 mb-md-0">
                    <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar size="48" variant="light-success">
                                <feather-icon size="24" icon="BookmarkIcon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                                {{ studi_kasus }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                                FORUM
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>


<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
    BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BTable, BPagination,
    BButtonToolbar, BButtonGroup, BDropdown, BDropdownItem, BFormInput, BModal
} from 'bootstrap-vue'

//call API
import API from '@/api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'


export default {
    components: {
        BCardCode,
        BTable,
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BMedia,
        BAvatar,
        BMediaAside,
        BMediaBody,
        API,
        vSelect,
        Ripple,
        BPagination,
        BButtonToolbar,
        BButtonGroup,
        BDropdown,
        BDropdownItem,
        BFormInput,
        BModal
    },
    data() {
        return {
            currentPage: 1,
            perPage: 20,
            rows: 0,

            calon_pengguna: '',
            anggota_idi_jakpus: '',
            anggota_non_idi_jakpus: '',
            studi_kasus: '',

            selected: [],


            fields: [{ key: 'id', label: 'id' }, { key: 'orang_email', label: 'users' }, { key: 'orang_nama_lengkap', label: 'nama' }, { key: 'orang_tanggal_lahir', label: 'tanggal lahir' }, { key: 'orang_tempat_lahir', label: 'tempat lahir' }, { key: 'orang_level', label: 'level anggota' }],
            items: [],

            selectMode: 'multi',
        }
    },
    methods: {

        onRowSelected(items) {
            this.selected = items
        },
        selectAllRows() {
            this.$refs.selectableTable.selectAllRows()
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected()
        },

        refreshPage() {
            //cuurent page dashboard
            if (this.currentPage == 1) {
                this.dashboard()
            } else {
                this.currentPage = 1
            }

        },

        async dashboard() {
            try {
                this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)

                const form = {
                    limit: this.perPage,
                    page: this.currentPage,
                    search: this.search,

                }
                const { data } = await API.dashboard.list(form)
                this.calon_pengguna = data.jml_calon_pengguna
                this.anggota_idi_jakpus = data.jml_agt_jakpus
                this.anggota_non_idi_jakpus = data.jml_agt_non_jakpus
                this.studi_kasus = data.jml_forum
                this.handleMsgSuccess('Berhasil tampilkan data dashboard')
            } catch (error) {
                console.log(error)
                this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
            } finally {
                this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
            }
        },

    },

    mounted() {
        this.dashboard()
    },

    watch: {
        currentPage() {
            this.dashboard()

        }
    }
}
</script>
<style lang="scss">
.b-table-selectable {
    .feather {
        font-size: 1.3rem;
    }
}
